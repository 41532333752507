.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

* {
    box-sizing: border-box;
}

/*.react-horizontal-scrolling-menu--item:first-child {*/
/*    margin-left: 50px;*/
/*}*/

.arrow-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    z-index: 10;
}


