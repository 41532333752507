.upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    border-radius: inherit; /* Inherits border-radius from the parent */
}

.dynamic-radius {
    transition: border-radius 0.3s ease;
}
